<template>
  <v-container
    fluid
    fill-height
    class="d-flex justify-start align-center flex-column pa-0"
  >
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <router-link :to="{ name: 'mypage-index' }">
            <img :src="require('@/assets/logo.svg')" width="180" height="88" />
          </router-link>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            color="#fff"
            class="py-1 pa-5 rounded-xl"
            rounded
            :class="$style.form"
            :min-width="320"
          >
            <v-card-text
              :class="$style.form_text"
              >{{ errorMessage }}<br>{{ errorMessageEn }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="text-center"
            :class="$style['logout-btn']"
            color="#FD6955"
            text
            @click="goHome"
          >
            HOMEに戻る (Home)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { errorMaps, errorMapsEn } from "./configs";
export default {
  props: {
    status: {
      type: Number,
      default: 401
    }
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return `エラー - ${this.status}`;
    },
    titleEn() {
      return `Error - ${this.status}`;
    },
    userType() {
      return this.$store.getters[`auth/userType`];
    },
    errorMessage() {
      return errorMaps[this.status];
    },
    errorMessageEn() {
      return errorMapsEn[this.status];
    },
    path() {
      return this.$route.path;
    }
  },
  methods: {
    movePage() {
      this.$router.push({ name: "mypage-index" }).catch(() => {});
    },
    goHome() {
      window.location.href = "https://jam-id.jp";
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {},
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
  }
};
</script>

<style module lang="scss">
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  margin: 85px 0 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.form_text {
  font-size: 13px !important;
  line-height: 1.6;
}

.logout-btn {
  font-size: 13px !important;
  font-family: $body-font-family;
}
</style>
